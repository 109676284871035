/* Refactoring this style file to useStyles will probably lead to exceptions into the sentry platform */
/* I think it's because error boundary component it's the only component who must be class base component and their children have issues with hooks for any reason in prod */

.errorFallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  padding: 48px;
  width: 332px;
  height: 536px;
}

.errorFallback .title {
  color: #0084a3;
  font-size: 60px;
}

.errorFallback .image {
  width: 90%;
  height: 150px;
}

.errorFallback .buttonText {
  font-weight: 600;
}

.errorFallback .buttonContainer {
  margin-top: 16px;
}

.errorFallback .reloadButton {
  width: 217px;
  height: 44px;
}

/* md viewport */
@media screen and (min-width: 360px) {
  .errorFallback {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 686px;
  }

  .errorFallback .image {
    max-width: 485px;
    height: 256px;
  }
}

/* md viewport */
@media screen and (min-width: 768px) {
  .errorFallback {
    width: 686px;
    height: 660px;
  }
}

/* lg viewport */
@media screen and (min-width: 1024px) {
  .errorFallback {
    width: 936px;
    height: 668px;
  }
}
