.container {
  border-radius: 20px;
  width: 400px;
  background-color: white;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px 36px 24px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
}

@keyframes jump {
  0% {
    transform: scale(1.25, 0.75);
  }
  50% {
    transform: scale(1, 1);
  }
  55% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-15deg);
  }
  65% {
    transform: rotate(15deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  100% {
    transform: scale(1, 1);
  }
}

.icon {
  animation: jump 1.2s;
  margin-bottom: 10px;
}
