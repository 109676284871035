html {
  font-size: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  height: 100vh;
  margin: 0;
  min-width: 375px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

span {
  font-family: "Roboto";
}

code {
  color: #e01a76;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn-primary {
  background-color: #1b6ec2;
  border-color: #1861ac;
  color: #fff;
}

#root {
  display: flex;
  height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }
}
